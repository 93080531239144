<template>
  <div>
    <div class="h-20 w-20 border border-gray-300 rounded-sm flex relative">
      <img
        class="h-full w-full object-cover"
        :class="{ hidden: !previewUrl }"
        alt="company logo"
        :src="previewUrl"
      />

      <div
        class="absolute -top-3 -right-3 h-6 w-6 flex items-center justify-center bg-white border border-gray-400 rounded-full"
        :class="{ hidden: !previewUrl }"
      >
        <button type="button" class="focus:outline-none focus:ring" @click="handleClearFile">
          <i class="tmlps-i-delete text-2xl"></i>
        </button>
      </div>

      <div class="flex-grow flex items-center justify-center" :class="{ hidden: previewUrl }">
        <button
          type="button"
          class="focus:outline-none focus:ring"
          :class="{ hidden: previewUrl }"
          @click="handleImageSelection"
        >
          <i class="tmlps-i-attach font-extrabold text-4xl"></i>
        </button>
        <input
          type="file"
          accept="image/png, image/jpeg"
          class="hidden"
          ref="fileInput"
          @change="handleSelectFile"
        />
      </div>
    </div>

    <slot name="caption"></slot>
  </div>
</template>

<script>
export default {
  name: 'ImagePreviewInput',
  props: {
    value: {},
    initialPreviewUrl: { type: String, default: null },
  },
  data() {
    return {
      previewUrl: this.initialPreviewUrl,
    };
  },
  methods: {
    handleImageSelection() {
      this.$refs.fileInput.click();
    },
    handleSelectFile(event) {
      if (!event.target.files.length) {
        return;
      }

      const file = event.target.files[0];
      this.previewUrl = URL.createObjectURL(file);
      this.$emit('input', file);
    },
    handleClearFile() {
      this.$emit('input', null);
      this.previewUrl = null;
    },
  },
};
</script>
