<template>
  <div class="flex items-center flex-wrap">
    <div
      class="h-6 w-6 mr-2 cursor-pointer flex items-center justify-center"
      :style="`background-color: ${color}`"
      v-for="(color, index) in colors"
      :key="index"
      @click="handleSelectColor(color)"
    >
      <span class="w-2 h-2 bg-gray-800 rounded-full" :class="{ hidden: selected !== color }"></span>
    </div>
  </div>
</template>

<script>
const colors = {
  gray: '#71717A',
  red: '#EF4444',
  amber: '#F59E0B',
  green: '#22C55E',
  emerald: '#10B981',
  teal: '#14B8A6',
  cyan: '#06B6D4',
  blue: '#3B82F6',
  indigo: '#4A62D4',
  violet: '#8B5CF6',
  fuchsia: '#D946EF',
  rose: '#F43F5E',
};

export default {
  name: 'BaseColorPicker',
  props: {
    value: { type: String, default: '' },
    colors: { type: Object, default: () => colors },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    handleSelectColor(color) {
      this.selected = color;
      this.$emit('input', color);
    },
  },
};
</script>
